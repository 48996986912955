import { call, put, takeLatest, all, select } from "redux-saga/effects";

import {
	APP_INIT,
	CHANGE_PASSWORD_REQUEST,
	FORGET_PASSWORD_REQUEST,
	LOGOUT,
	SIGN_IN,
	SIGNUP_REQUEST,
} from "../../../constants/redux";
import { loginSuccess, logout, refreshSuccess, signupFailure, signupSuccess } from "../../actions/auth";
import { clearAuthFromStorage, loadAuthFromStorage, saveAuthToStorage } from "../../../utils/authStorage";
import { AnyAction } from "redux";
import { commonApiAuthCall } from "../../apiAuthCaller";
import { API_ENDPOINTS } from "../../../constants/apiConstants";
import { selectAccessToken } from "../../selectors/authSelectors";
import { ShowAlert } from "../../actions/alert";
import { COMMON_SAVE_ERROR_MESSAGE, COMMON_SUCCESSFULL_MESSAGE } from "../../../constants/redux/messages";
const { LOGIN, REFRESH, REGISTER, LOGOUT: LOGOUT_API, FORGOT_PASSWORD, CHANGE_PASSWORD } = API_ENDPOINTS;
type GeneratorReturnType<T> = Generator<any, T, any>;

function* handleSignIn(action: any): GeneratorReturnType<void> {
	try {
		// Make a request to the authentication endpoint with provided credentials
		const response = yield call(
			commonApiAuthCall,
			LOGIN,
			"POST",
			{ username: action.payload.username, password: action.payload.password },
			{}
		);

		if (response?.AuthenticationResult) {
			const authData = {
				accessToken: response.AuthenticationResult.AccessToken,
				idToken: response.AuthenticationResult.IdToken,
				refreshToken: response.AuthenticationResult.RefreshToken,
				expiresAt: Date.now() + response.AuthenticationResult.ExpiresIn * 1000, // Calculate expiration time
			};

			// Save tokens to localStorage
			saveAuthToStorage(authData);

			// Dispatch login success action
			yield put(loginSuccess(authData));
			action.payload.onSuccess();
		} else {
			// Handle authentication error
			console.error("Authentication failed");
			action.payload.onError("Login failed");
		}
	} catch (error) {
		console.error("Error during sign-in:", error);
		yield put(logout());
		action.payload?.onError(error || "Login failed");
	}
}

function* validateAuth(): GeneratorReturnType<void> {
	const auth = loadAuthFromStorage();
	if (auth && Date.now() < auth.expiresAt) {
		yield put(loginSuccess(auth));
	} else if (auth?.refreshToken) {
		try {
			const response = yield call(commonApiAuthCall, REFRESH, "POST", {
				refreshToken: auth.refreshToken,
			});
			const newAuth = {
				accessToken: response?.AccessToken,
				idToken: response.IdToken,
				refreshToken: auth.refreshToken,
				expiresAt: Date.now() + response.ExpiresIn * 1000,
			};
			saveAuthToStorage(newAuth);
			yield put(refreshSuccess(newAuth));
		} catch (error) {
			yield put(logout());
		}
	} else {
		yield put(logout());
	}
}

function* signupSaga(action: AnyAction): GeneratorReturnType<void> {
	try {
		const data = { ...action.payload };
		delete data.email;
		const response = yield call(commonApiAuthCall, REGISTER, "POST", {
			username: action.payload?.email ?? "",
			...data,
		}); 
		const user = response; 
		if (user.statusCode === "400" || user.errorType) throw new Error("Signup Failed");
		yield put(signupSuccess(user)); // Dispatch success action
		if (action?.onSuccess) action?.onSuccess(user);
	} catch (error) {
		yield put(signupFailure((error as Error).message || "Signup failed")); // Dispatch failure action
		if (action?.onFailure) action?.onFailure(Error);
	}
}

function* logoutSaga(): GeneratorReturnType<void> {
	try {
		const auth = loadAuthFromStorage();

		// Make sure to replace '/auth/logout' with your actual logout API endpoint
		const response = yield call(commonApiAuthCall, LOGOUT_API, "POST", { accesstoken: auth?.accessToken });

		// If the API call is successful, you can do additional actions here if necessary
		console.log("Logout successful:", response);

		// Optionally, handle server-side errors or success
	} catch (error) {
		console.error("Logout error:", error);
		// Handle logout failure, possibly dispatch another action for failure
	} finally {
		// Clear the auth data from localStorage
		clearAuthFromStorage();
	}
}

function* forgetPasswordSaga(action: AnyAction) {
	const { email } = action.payload;
	const { onSuccess, onError } = action;

	try {
		// Replace with your actual API endpoint
		yield call(commonApiAuthCall, FORGOT_PASSWORD, "POST", { email });

		// Trigger success callback
		if (onSuccess) onSuccess();
	} catch (error: any) {
		console.error("Forget password error:", error);

		// Trigger error callback
		if (onError) onError(error.response || "Failed to send reset email");
	}
}

function* changePasswordSaga(action: AnyAction): GeneratorReturnType<void> {
	try {
		const accesstoken: string | null = yield select(selectAccessToken);
		const { oldpassword, newpassword, onSuccess } = action.payload;

		const response = yield call(commonApiAuthCall, CHANGE_PASSWORD, "POST", {
			accesstoken,
			oldpassword,
			newpassword,
		});
		if(response?.message) throw new Error(response?.message)
		if (onSuccess) onSuccess(response);
		yield put(
			ShowAlert({
				showAlert: true,
				message: response?.message || COMMON_SUCCESSFULL_MESSAGE,
				type: "success",
			})
		);
	} catch (error) {
		if(action.payload?.onFailure)action.payload?.onFailure(error);
		yield put(
			ShowAlert({
				showAlert: true,
				message: (error as Error).message || COMMON_SAVE_ERROR_MESSAGE,
				type: "error",
			})
		);
	}
}

export function* watchSignIn() {
	yield takeLatest(SIGN_IN, handleSignIn);
	yield takeLatest(APP_INIT, validateAuth);
	yield takeLatest(SIGNUP_REQUEST, signupSaga);
	yield takeLatest(LOGOUT, logoutSaga);
	yield takeLatest(FORGET_PASSWORD_REQUEST, forgetPasswordSaga);
	yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga);
}

export function* authSaga() {
	yield all([watchSignIn()]);
}
